#landingBackground{
    background-image: url('../../../assets/img/bg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.typewriter{
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

/* DEMO-SPECIFIC STYLES */
.typewriter h1 {
    font-size: 5em;
    font-weight: bold;
    font-family: monospace;
    overflow: hidden;
    white-space: nowrap;
    color: whitesmoke;
    letter-spacing: 0.05em;
}