.error-message{
    color: #dc3545;
    font-size: smaller;
    padding: 5px;
}

.error-input{
    border-color: #d4646f;
}

.error-input:hover{
    border-color: #dc3545;
}

.error-input:focus{
    border-color: #dc3545;
}

.css-yk16xz-control{
    display: flex;
    flex: 1;
}
.css-1pahdxg-control{
    display: flex;
    flex: 1;
}