.cssload-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cssload-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
  transition: top 0.48s linear 1.235s;
  -o-transition: top 0.48s linear 1.235s;
  -ms-transition: top 0.48s linear 1.235s;
  -webkit-transition: top 0.48s linear 1.235s;
  -moz-transition: top 0.48s linear 1.235s;
}
.cssload-container:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
  transition: bottom 0.48s linear 1.235s;
  -o-transition: bottom 0.48s linear 1.235s;
  -ms-transition: bottom 0.48s linear 1.235s;
  -webkit-transition: bottom 0.48s linear 1.235s;
  -moz-transition: bottom 0.48s linear 1.235s;
}
.cssload-container.done:before {
  top: -50%;
}
.cssload-container.done:after {
  bottom: -50%;
}

.cssload-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform-origin: center;
  -o-transform-origin: center;
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
}
.cssload-progress .cssload-progress-item {
  text-align: center;
  width: 97px;
  height: 97px;
  line-height: 97px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
}
.cssload-progress .cssload-progress-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -3px;
  margin-left: 0px;
  width: 44px;
  height: 44px;
  border-top: solid 10px rgb(0, 0, 0);
  border-right: solid 10px rgb(0, 0, 0);
  border-top-right-radius: 100%;
  transform-origin: left bottom;
  -o-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  -webkit-transform-origin: left bottom;
  -moz-transform-origin: left bottom;
  animation: spin 1.2s linear infinite;
  -o-animation: spin 1.2s linear infinite;
  -ms-animation: spin 1.2s linear infinite;
  -webkit-animation: spin 1.2s linear infinite;
  -moz-animation: spin 1.2s linear infinite;
}
.cssload-progress.cssload-float .cssload-progress-item:before {
  border-top-width: 2px;
  margin-top: 0px;
  height: 49px;
}
.cssload-progress.cssload-float.cssload-shadow:before {
  border-top-width: 2px;
  margin-top: -40px;
  height: 49px;
}
.cssload-progress.cssload-shadow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -42px 0 0 12px;
  width: 44px;
  height: 44px;
  border-top: solid 10px rgb(221, 221, 221);
  border-right: solid 10px rgb(221, 221, 221);
  border-top-right-radius: 100%;
  z-index: -1;
  transform-origin: left bottom;
  -o-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  -webkit-transform-origin: left bottom;
  -moz-transform-origin: left bottom;
  animation: spin 1.2s linear infinite;
  -o-animation: spin 1.2s linear infinite;
  -ms-animation: spin 1.2s linear infinite;
  -webkit-animation: spin 1.2s linear infinite;
  -moz-animation: spin 1.2s linear infinite;
}
.cssload-progress.cssload-shadow:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 97px;
  height: 97px;
  color: rgb(221, 221, 221);
  text-align: center;
  line-height: 97px;
  border: 2px solid rgb(221, 221, 221);
  margin: -39px 0 0 -39px;
  z-index: -1;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  transform-origin: center;
  -o-transform-origin: center;
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  100% {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
